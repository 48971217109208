import React from 'react'

import Layout from '../../components/Layout'
import BlogRoll from '../../components/BlogRoll'
import { themeStyles } from '../../utils/theme'

const styles = {
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: `${themeStyles.elementMargin.md} 0`,
    ...themeStyles.contentWidth,
    ...themeStyles.textPadding,
  },
}
export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout metadataTitle='LoanSnap - Blog' metadataDescription='Find our last communication'>
        <div css={styles.contentContainer}>
          <h1 css={[themeStyles.sectionHeader, { textAlign: 'left', width: '100%', padding: '0 20px' }]}>
            Recent posts
          </h1>
          <BlogRoll />
        </div>
      </Layout>
    )
  }
}
