import React from 'react'
import PropTypes from 'prop-types'
import CascadeLink from '../components/CascadeLink'
import { graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import { themeStyles, colors } from '../utils/theme'

const styles = {
  featuredImageHolder: {
    maxWidth: '270px',
    height: '11rem',
    overflow: 'hidden',
    width: '100%',
  },
  container: {
    width: '270px',
    height: '25rem',
    minHeight: '300px',
    margin: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    ...themeStyles.boxShadow,
  },
  name: {
    color: colors.blueGreen,
  },
  title: {
    marginTop: '10px',
    color: colors.grey,
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  textHolder: {
    padding: '20px 10px 30px',
    height: '14rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    
    '& p': {
      margin: 0,
      paddingBottom: '20px',
    }
  },
}

class BlogRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark
    return (
      <div css={styles.rowContainer}>
        {posts &&
          posts.map(({ node: post }) => (
            <div key={post.id}>
              <div css={styles.container}>
                <CascadeLink
                  analyticsTargetName='Blog Post Thumbnail'
                  to={post.fields.slug}
                  css={{ textDecoration: 'none', color: colors.grey, fontWeight: 600, height: '100%' }}
                >
                  {post.frontmatter.featuredImage ? (
                    <div css={styles.featuredImageHolder}>
                      <PreviewCompatibleImage
                        style={styles.picture}
                        imageInfo={{
                          image: post.frontmatter.featuredImage,
                          alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                        }}
                      />
                    </div>
                  ) : null}
                  <div css={styles.textHolder}>
                    <p css={{ textDecoration: 'none', color: colors.grey, fontWeight: 600 }}>{post.frontmatter.title}</p>
                    <p>{post.frontmatter.description}</p>
                    <p css={{ textDecoration: 'none', color: colors.shamrock, fontSize: '15px', fontWeight: 400}}>Read More...</p>
                  </div>
                </CascadeLink>
              </div>
            </div>
          ))}
      </div>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" }, published: { eq: true } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                description
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredImage {
                  childImageSharp {
                    gatsbyImageData(width: 400, quality: 100, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)
